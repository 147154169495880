import Vue from 'vue';
import Vuex from 'vuex';
import VueResource from 'vue-resource';
Vue.use(VueResource);
Vue.use(Vuex);

import timeFunctions from "../../js/timeFunctions";
import axios from 'axios';

const newOrderTemplate = {
  phone: "",
  extension: "",
  orderNum: "",
  name: "",
  address: "",
  entry_date: "",
  entry_time: "",
  full_date: "",
  special_instructions: "",
  is_scheduled: false,
  ignore_prep_time: false,
  payment: "",
  total: 0.00,
  tip: 0.00,
}

const newStoreTransaction = {
  amount: 0,
  transactionType: "",
  transactionDateTime: "",
  storeId: "",
  isOpen: ""
}

const accountReportModel = {
  startDate: "",
  endDate: "",
  startTime: "",
  endTime: "",
  driversIds: ""
}

// initial state
const state = {
  all: [],
  allWithVoid: [],
  currentDayOrders: [],
  driversPayRollReport: [],
  nextRoute: [],
  draggedOrder: {},
  newOrder: {
    ...newOrderTemplate
  },
  newTranscation: {
    ...newStoreTransaction
  },
  accountReport: {
    ...accountReportModel
  },
  editOrder: {},
  newOrderError: "",
  editOrderError: "",
  isOrderSaving: false,
  orderStatusChanging: [],
  selectedOrders: [],
  orderToDelete: null,
  store: {},
  openDrivers: [],
  openDriversModalStatus: false,
  isOpenCloseAllDriverModal: false,
  openDayFile: {},
  directionsDisplayCache: [],
  assignTentativeRouteController: null
}

function elapsedTimeFunc(value) {
  const now = new Date();
  const entryTime = value;
  const ELP = now - entryTime;
  return Math.round(ELP / 60000);
}

function deliveredSort(a, b) {
  var a_Delivered, b_Delivered
  
  if(a.delivery_time){
    a_Delivered = new Date(parseInt(a.delivery_time.substring(6, a.delivery_time.length - 2)));
  } else {
    a_Delivered = new Date(parseInt(a.ExternalEstimatedTimeToDeliver.substring(6, a.ExternalEstimatedTimeToDeliver.length - 2)));
  }
  if(b.delivery_time){
    b_Delivered = new Date(parseInt(b.delivery_time.substring(6, b.delivery_time.length - 2)));
  } else {
    b_Delivered = new Date(parseInt(b.ExternalEstimatedTimeToDeliver.substring(6, b.ExternalEstimatedTimeToDeliver.length - 2)));
  }

  return a_Delivered - b_Delivered;
}

function routeSort(a, b) {
  var a_ETA, b_ETA;
  if (a.estimated_delivery_time && b.estimated_delivery_time) {
    a_ETA = new Date(parseInt(a.estimated_delivery_time.substring(6, a.estimated_delivery_time.length - 2)));
    b_ETA = new Date(parseInt(b.estimated_delivery_time.substring(6, b.estimated_delivery_time.length - 2)));
    return a_ETA - b_ETA
  } else {
    a_ETA = a.target_delivery_time_minutes - elapsedTimeFunc(new Date(parseInt(a.entry_time.substring(6, a.entry_time.length - 2))));
    b_ETA = b.target_delivery_time_minutes - elapsedTimeFunc(new Date(parseInt(b.entry_time.substring(6, b.entry_time.length - 2))));
    return a_ETA - b_ETA
  }
}

function shouldSortOrders(packageId){
  if(packageId == 1){
    return false
  }

  return true;
}

function ordersSort(drivers, packageId) {
  return function (a, b) {
    var a_ETA, b_ETA;

    if (a.estimated_delivery_time && b.estimated_delivery_time && a.estimated_driver_id && b.estimated_driver_id) {
      a_ETA = new Date(parseInt(a.estimated_delivery_time.substring(6, a.estimated_delivery_time.length - 2)));
      b_ETA = new Date(parseInt(b.estimated_delivery_time.substring(6, b.estimated_delivery_time.length - 2)));

      const a_driver = drivers.filter(driver => driver.driver_id == a.estimated_driver_id)[0];
      const b_driver = drivers.filter(driver => driver.driver_id == b.estimated_driver_id)[0];

      var a_LastArrival = a_driver ?
        a_driver.last_returned_to_store ? new Date(parseInt(a_driver.last_returned_to_store.substring(6, a_driver.last_returned_to_store.length - 2))) : -1 :
        -1;
      var b_LastArrival = b_driver ?
        b_driver.last_returned_to_store ? new Date(parseInt(b_driver.last_returned_to_store.substring(6, b_driver.last_returned_to_store.length - 2))) : -1 :
        -1;

      var a_time_till_back_to_shop = a_driver ? a_driver.time_til_back_to_shop : 0;
      var b_time_till_back_to_shop = b_driver ? b_driver.time_til_back_to_shop : 0;

      return a_time_till_back_to_shop - b_time_till_back_to_shop ||
        a_LastArrival - b_LastArrival ||
        a.estimated_driver_id - b.estimated_driver_id ||
        a_ETA - b_ETA;

    } else if (a.estimated_delivery_time && b.estimated_delivery_time) {
      a_ETA = new Date(parseInt(a.estimated_delivery_time.substring(6, a.estimated_delivery_time.length - 2)));
      b_ETA = new Date(parseInt(b.estimated_delivery_time.substring(6, b.estimated_delivery_time.length - 2)));
      return a_ETA - b_ETA
    } else {
      if(!shouldSortOrders(packageId)){
        return 0;
      }
      a_ETA = a.target_delivery_time_minutes - elapsedTimeFunc(new Date(parseInt(a.entry_time.substring(6, a.entry_time.length - 2))));
      b_ETA = b.target_delivery_time_minutes - elapsedTimeFunc(new Date(parseInt(b.entry_time.substring(6, b.entry_time.length - 2))));
      return a_ETA - b_ETA
    }
  }
}

function ordersSortNoRoute(drivers) {
  return function sort(a, b) {
    const a_ETA = a.target_delivery_time_minutes - elapsedTimeFunc(new Date(parseInt(a.entry_time.substring(6, a.entry_time.length - 2))));
    const b_ETA = b.target_delivery_time_minutes - elapsedTimeFunc(new Date(parseInt(b.entry_time.substring(6, b.entry_time.length - 2))));

    const a_Ready = a.is_ready_to_deliver ? -1 : 0;
    const b_Ready = b.is_ready_to_deliver ? -1 : 0;

    const a_assigned = a.driver_id == null ? 1 : 0;
    const b_assigned = b.driver_id == null ? 1 : 0;

    const a_driver = drivers.filter(driver => driver.driver_id == a.driver_id)[0];
    const b_driver = drivers.filter(driver => driver.driver_id == b.driver_id)[0];

    const a_LastArrival = a_driver ?
      a_driver.last_returned_to_store ? new Date(parseInt(a_driver.last_returned_to_store.substring(6, a_driver.last_returned_to_store.length - 2))) : -1 :
      -1;
    const b_LastArrival = b_driver ?
      b_driver.last_returned_to_store ? new Date(parseInt(b_driver.last_returned_to_store.substring(6, b_driver.last_returned_to_store.length - 2))) : -1 :
      -1;

    return a_assigned - b_assigned ||
      a_LastArrival - b_LastArrival ||
      a_Ready - b_Ready ||
      a_ETA - b_ETA;
  }
}

function ordersEntrySort(a, b) {
  var a_entry, b_entry
  a_entry = new Date(parseInt(a.entry_time.substring(6, a.entry_time.length - 2)))
  b_entry = new Date(parseInt(b.entry_time.substring(6, b.entry_time.length - 2)))

  return a_entry - b_entry
}

// getters
const getters = {
  inStoreOrders: (state, getters, rootState) => {
    const drivers = rootState.drivers.all;
    const array = state.all.filter(function (order) {
      if (!order.delivery_time && !order.time_driver_left &&
        !order.is_return && !order.pending && (order.external_delivery_id ? order.external_delivery_status === 'pending' : true)) {
        return order
      }
    });

    if(![1,2].includes(getters.getStore.packageId)){
      array.sort(ordersSort(drivers, getters.getStore.packageId));
    }
  
    return array
  },
  inStoreNotAssignedOrders: (state, getters, rootState) => {
    const drivers = rootState.drivers.all;
    const array = state.all.filter(function (order) {
      if (!order.delivery_time && !order.time_driver_left &&
        !order.is_return && !order.pending &&
        order.driver_id == null && (order.external_delivery_source == null || order.external_delivery_source == '')
        && (order.external_delivery_id ? order.external_delivery_status === 'pending' : true)) {
        return order
      }
    });

    array.sort(ordersSort(drivers, getters.getStore.packageId));
    return array
  },
  unroutedOrders: (state, getters, rootState) => {
    const drivers = rootState.drivers.all;
    const uniqueOrderIds = new Set();

    const filteredAndUniqueOrders = state.all.filter(order => {
      if (!order.delivery_time &&
          !order.time_driver_left &&
          !order.is_return &&
          !order.pending &&
          order.driver_id === null &&
          (order.external_delivery_id == null || order.external_delivery_id == '')) {
  
        if (!uniqueOrderIds.has(order.order_id)) {
          uniqueOrderIds.add(order.order_id);
          return true;
        }
      }
      return false;
    });
  
    filteredAndUniqueOrders.sort(ordersSortNoRoute(drivers));
  
    return filteredAndUniqueOrders;
  }, 
  assignedOrdersToDrivers: (state, getters) => {
    var orders = state.all.filter(
      o => o.driver_id != null &&
        o.estimated_driver_id != null
    );

    return orders;
  },
  assignedOrders: (state, getters) => {
    return getters.inStoreOrders.filter(o => o.driver_id || 
        ((o.external_delivery_id != null || o.external_delivery_id != '') && o.external_delivery_status === 'pending'));
  },
  assignedOrdersByDriverId: (state, getters) => (driver_id) => {
    var orders = state.all.filter(
      o => o.driver_id == driver_id &&
        o.estimated_driver_id != null
    );
    if(![1,2].includes(getters.getStore.packageId)){
      orders.sort(routeSort);
    }

    return orders;
  },
  tentativeOrdersByDriverId: (state) => (driver_id) => {
    var orders = state.all.filter(
      o => o.estimated_driver_id == driver_id &&
      o.driver_id == null && o.next_driver_route);
    var selectedOrders = state.selectedOrders;

    if (shouldSortOrders(state.store.packageId)) {
      orders.sort(routeSort);
    } else if (selectedOrders.length != 0 && orders.length != 0) {
      orders = selectedOrders
        .map(orderId => orders?.find(order => order?.order_id === orderId))
        .filter(order => order);
    }
    return orders;
  },
  orderCountByDriverId: (state) => (driver_id) => {
    return state.all.filter(o => o.driver_id == driver_id).length;
  },
  readyOrders: (state, getters, rootState) => {
    const drivers = rootState.drivers.all;
    const array = state.all.filter(order => order.is_ready_to_deliver);
    array.sort(ordersSort(drivers, getters.getStore.packageId));
    return array;
  },
  outsourcedPickupOrders: (state, getters, rootState) => {
    const orders = state.all.filter(function (order) {
      if (order.external_delivery_status === 'pickup') {
        return order;
      }
    });

    orders.sort(ordersEntrySort)
    return orders;
  },
  onTheWayOrders: (state, getters, rootState) => {
    const drivers = rootState.drivers.all;
    const otw_orders_unsorted = state.all.filter(function (order) {
      if ((order.driver_id && order.time_driver_left &&
        !order.delivery_time && !order.is_return) || order.external_delivery_status === 'on the way') {
        return order
      }
    });
    otw_orders_unsorted.sort(ordersSort(drivers, getters.getStore.packageId));
    return otw_orders_unsorted
  },
  getOrdersBySearch: (state) => (search) => {
    return state.all.filter(
      o => o.phone.indexOf(search) >= 0 ||
        o.order_number.toString().indexOf(search) >= 0 ||
        o.customer_name.toLowerCase().indexOf(search.toLowerCase()) >= 0 ||
        o.address.toLowerCase().indexOf(search.toLowerCase()) >= 0);
  },
  deliveredOrders: state => {
    return state.all
      .filter(order => order.delivery_time || (order.external_delivery_status === 'delivered'))
      .map(order => ({
        ...order,
        time_on_road: order.external_delivery_id !== null && order.delivery_time && order.PickupTime
          ? Math.ceil((parseInt(order.delivery_time.match(/\d+/)[0]) - parseInt(order.PickupTime.match(/\d+/)[0])) / (60 * 1000))
          : order.time_on_road
      }))
      .sort(deliveredSort);
  },
  returnedOrders: state => {
    return state.all.filter(function (order) {
      if (order.is_return) {
        return order
      }
    })
  },
  pendingOrders: state => {
    return state.all.filter(function (order) {
      if (order.pending) {
        return order
      }
    })
  },
  outsourcedOrders: state => {
    return state.all.filter(order => order.external_delivery_id && order.external_delivery_id !== '');
  },
  inStoreNotNext: (state, getters, rootState) => {
    const drivers = rootState.drivers.all;
    const array = state.all.filter(function (order) {
      if (!order.next_route && !order.driver_id && !order.pending &&
        !order.delivery_time && !order.is_return && (order.external_delivery_id == null || order.external_delivery_id == '')) {
        return order;
      }
    });
    array.sort(ordersSort(drivers, getters.getStore.packageId));
    return array;
  },
  nextRoute: (state, getters, rootState) => {
    const drivers = rootState.drivers.all;
    const array = state.all.filter(function (order) {
      if (order.next_route && !order.driver_id && !order.pending &&
        !order.delivery_time && !order.is_return && (order.external_delivery_id == null || order.external_delivery_id == '')) {
        return order;
      }
    });
    array.sort(ordersSort(drivers, getters.getStore.packageId));
    return array;
  },
  directionsDisplayCache: (state) => {
    return state.directionsDisplayCache;
  },
  averagePrepTime: (state) => {
    return state.all.length ? state.all[0].prep_time : "--";
  },
  getStoreID(state, getters, rootState) {
    return rootState.user.store_id;
  },
  getBaseURL(state, getters, rootState) {
    return rootState.base_url;
  },
  getHubURL(state, getters, rootState) {
    return rootState.hub_url;
  },
  getSelectedDriver(state, getters, rootState) {
    return rootState.drivers.selected;
  },
  getAutoRouteSetting(state, getters, rootState) {
    return rootState.settings["auto_route_drivers"];
  },
  getIsOrderReadyRequired(state, getters, rootState) {
    return rootState.settings["IsOrderReadyRequired"];
  },
  getDefaultOrderPrepTime(state, getters, rootState) {
    return rootState.settings["average_order_prep_time"];
  },
  getNewOrder: state => {
    return state.newOrder;
  },
  getEditOrder: state => {
    return state.editOrder;
  },
  orderStatusChanging: state => {
    return state.orderStatusChanging;
  },
  selectedOrders: state => {
    return state.selectedOrders;
  },
  getCurrentDayOrders: state => {
    return state.currentDayOrders;
  },
  openStore: state => {
    return state.newTranscation;
  },
  closeStore: state => {
    return state.newTranscation;
  },
  getApiUrl(state, getters, rootState) {
    return rootState.api_url;
  },
  getPayRollReport: state => {
    return state.driversPayRollReport;;
  },
  getStore: state => {
    return state.store;
  },
  getIsOpenCloseAllDriverModalStatus: state => {
    return state.isOpenCloseAllDriverModal;
  },
  getAllOrdersWithVoid: state => {
    const allOrders = [ ...state.allWithVoid ];
    allOrders.sort((a, b) => b.entry_time - a.entry_time);
    return allOrders;
  },
  getShowPromptModal: (state, getters, rootState) => {
    return rootState.app.showPromptModal
  },
  getAllDrivers: (state, getters, rootState) => {
    return rootState.drivers.all;
  },
  getOpenDayFile: (state, getters, rootState) => {
    return state.openDayFile;
  }
}

// actions
const actions = {
  saveNewOrder({
    dispatch,
    getters
  }, newOrder) {
    const easternEntryDateTime = timeFunctions.convertEasternTime(`${newOrder.entry_date} ${newOrder.entry_time}`);

    const easternEntryDate = easternEntryDateTime.format('YYYY-M-D');
    const easternEntryTime = easternEntryDateTime.format('HH:mm:ss');

    var payload = {
      store_id: getters.getStoreID,
      method: "PUT",
      address: newOrder.address,
      entry_date: easternEntryDate,
      entry_time: easternEntryTime,
      order_number: newOrder.orderNum !== null && newOrder.orderNum.trim() !== "" ? newOrder.orderNum : undefined,
      customer_name: newOrder.name,
      phone: newOrder.phone,
      extension: newOrder.extension,
      total: newOrder.total,
      payment: newOrder.payment,
      special_instructions: newOrder.special_instructions,
      tip: parseFloat(newOrder.tip),
      is_scheduled: newOrder.is_scheduled,
      ignore_prep_time: newOrder.ignore_prep_time,
      deliver_date: newOrder.deliver_date,
      deliver_time: newOrder.deliver_time,
      size: newOrder.size
    };
    Vue.http.post(getters.getBaseURL + "order/", payload).then(
      (res) => {
        if (res.body.result != 0) {
          dispatch("orders/setNewOrderError", res.body.error, {
            root: true
          });
        } else {
          dispatch("orders/clearNewOrder", null, {
            root: true
          });
          dispatch("app/setPanel", "order", {
            root: true
          });
        }

        dispatch("orders/addOrderCustomerInfo", {
          phoneNumber: newOrder.phone,
          customerName: newOrder.name,
          address: newOrder.address,
          deliveryInstructions: newOrder.special_instructions,
          storeId: getters.getStoreID
        }, {
          root: true
        })

      },
      (res) => {
        console.log("failed", res.body);
      }
    );
  },
  saveEditOrder({
    dispatch,
    getters
  }, editOrder, a = false) {

    const easternEntryDateTime = timeFunctions.convertEasternTime(`${editOrder.entry_date} ${editOrder.entry_time}`);
    const easternEntryDate = editOrder.entry_date ? easternEntryDateTime.format('YYYY-M-D') : null;
    const easternEntryTime = editOrder.entry_date ? easternEntryDateTime.format('HH:mm:ss') : null;

    let easternDeliverDateTime = null;
    let easternDeliverDate = null;
    let easternDeliveryTime = null;

    let schedule_type = "";
    if (editOrder.is_scheduled) {
      schedule_type = "SCHEDULED";
      easternDeliverDateTime = timeFunctions.convertEasternTime(`${editOrder.deliver_date} ${editOrder.deliver_time}`);
      easternDeliverDate = easternDeliverDateTime.format('YYYY-M-D');
      easternDeliveryTime = easternDeliverDateTime.format('HH:mm:ss');
    }

    var payload = {
      store_id: getters.getStoreID,
      order_id: editOrder.order_id,
      method: "POST",
      address: editOrder.address,
      entry_date: easternEntryDate,
      entry_time: easternEntryTime,
      pending: editOrder.pending,
      deliver_date: easternDeliverDate,
      deliver_time: easternDeliveryTime,
      delivery_time: editOrder.delivery_time,
      order_number: editOrder.order_number,
      customer_name: editOrder.customer_name,
      phone: editOrder.phone,
      extension: editOrder.extension,
      total: editOrder.total,
      payment: editOrder.payment,
      special_instructions: editOrder.special_instructions,
      tip: parseFloat(editOrder.tip),
      schedule_type: schedule_type,
      ignore_prep_time: editOrder.ignore_prep_time,
      external_delivery_id: editOrder.external_delivery_id,
      external_delivery_source: editOrder.external_delivery_source,
      external_delivery_status: editOrder.external_delivery_status,
      overrideFields: editOrder.overrideFields ?? true,
      size: editOrder.size,
      OrderType: editOrder.OrderType,
    };

    dispatch("orders/setIsOrderSaving", true, {
      root: true
    });

    Vue.http.post(getters.getBaseURL + "order/", payload).then(
      (res) => {
        dispatch("orders/setIsOrderSaving", false, {
          root: true
        });

        if (res.body.result != 0) {
          dispatch("orders/setEditOrderError", res.body.error, {
            root: true
          });
        } else {
          dispatch("app/setPanel", "order", {
            root: true
          });
        }
        dispatch("orders/addOrderCustomerInfo", {
          phoneNumber: editOrder.phone,
          customerName: editOrder.customer_name,
          address: editOrder.address,
          deliveryInstructions: editOrder.special_instructions,
          storeId: getters.getStoreID,
        }, {
          root: true
        })
      },
      (res) => {
        dispatch("orders/setIsOrderSaving", false, {
          root: true
        });
        console.log("failed", res);
      }
    );
  },
  editOrderTip({
    commit,
    getters
  }, payload) {
    Vue.http.post(getters.getHubURL + "order/updateTip/", payload).then(
      (res) => {

      },
      (res) => {
        console.log("failed", res);
      }
    );
  },
  deleteOrder({
    dispatch,
    getters
  }, order_id) {
    var payload = {
      store_id: getters.getStoreID,
      method: "DELETE",
      order_id: order_id,
    };

    if(!getters.getShowPromptModal){
      dispatch("app/togglePromptModal", 'DELETE_ORDER', {
        root: true
      });
      return
    } else {
      dispatch("app/togglePromptModal", 'DELETE_ORDER', {
        root: true
      });
    }

    Vue.http.post(getters.getBaseURL + "order/", payload).then(
      (res) => {
        if (res.body.result != 0) {
          alert(res.body.error);
        }
      },
      (res) => {
        console.log("Delete failed", res.body);
      }
    );
  },
  markOrderAsVoid({
    dispatch,
    getters
  }, order_id) {
    const body = {
      orderId: order_id,
      isVoid: true,
      storeId: getters.getStoreID,
    }
    Vue.http.post(getters.getApiUrl + "api/v1/orders/UpdateOrderVoidStatus/", body).then(
      (res) => {
        dispatch("app/togglePromptModal", 'DELETE_ORDER', {
          root: true
        });
      },
      (res) => {
        console.log(res.body);
      }
    );
  },
  reactivateOrder({
    dispatch,
    getters
  }, order_id) {
    const body = {
      orderId: order_id,
      isVoid: false,
      storeId: getters.getStoreID,
    }
    Vue.http.post(getters.getApiUrl + "api/v1/orders/UpdateOrderVoidStatus/", body)
  },
  assignOrders({
    dispatch,
    getters
  }, {
    driver,
    orders
  }) {
    if (state.assignTentativeRouteController) {
      state.assignTentativeRouteController.cancel('User canceled the operation.');
      state.assignTentativeRouteController = null; // Reset the controller after cancellation
    }

    var payload = {
      store_id: getters.getStoreID,
      method: "POST",
      orders: orders,
      driver_id: driver.driver_id,
    };
    const selectedOrders = state.all.filter(o => orders.indexOf(o.order_id) > -1);

    // check if store is open
    let store = getters.getStore
    if(!store.isOpen){
      dispatch("app/togglePromptModal", 'NOT_OPEN', {
        root: true
      });
      return
    }

    //check if order is already assigned and driver left
    if (selectedOrders.filter(o => o.time_driver_left !== null).length > 0) {
      if(!getters.getShowPromptModal){
        dispatch("app/togglePromptModal", 'ASSIGN_ORDER_LEFT', {
          root: true
        });
        return
      } else {
        dispatch("app/togglePromptModal", 'ASSIGN_ORDER_LEFT', {
          root: true
        });
      }
    }

    //check if driver has left yet
    if(driver.time_driver_left){
      if(!getters.getShowPromptModal){
        dispatch("app/togglePromptModal", 'ASSIGN_ORDER_DRIVER_LEFT', {
          root: true
        });
        return
      } else {
        dispatch("app/togglePromptModal", 'ASSIGN_ORDER_DRIVER_LEFT', {
          root: true
        });
      }

      //check if orders are not ready
      if (getters.getIsOrderReadyRequired && selectedOrders.filter(o => o.is_ready_to_deliver == false).length > 0){
        if(!getters.getShowPromptModal){
          dispatch("app/togglePromptModal", 'NOT_READY', {
            root: true
          });
          return
        } else {
          dispatch("app/togglePromptModal", 'NOT_READY', {
            root: true
          });
        }
      }
    }
    
    Vue.http.post(getters.getBaseURL + "orders/assign/", payload).then(
      (res) => {
        if (res.body.result != 0) {
          if(res.body.result == 3){
            dispatch("app/togglePromptModal", 'NO_DRIVER_SELECTED', {
              root: true
            });
          }
          else{
            alert(res.body.error)
          }
        }else{
          dispatch("orders/clearSelectedOrders", null, {
            root: true
          });
        }
      },
      (res) => {
        console.log("Failed to assign orders...");
      }
    );
  },
  unassignOrders({
    dispatch,
    getters,
    state
  }, orders) {
    var payload = {
      store_id: getters.getStoreID,
      method: "POST",
      orders: orders,
    };

    const selectedOrders = state.all.filter(o => orders.indexOf(o.order_id) > -1);
    if (selectedOrders.filter(o => o.time_driver_left).length > 0) {
      if(!getters.getShowPromptModal){
        dispatch("app/togglePromptModal", 'UNASSIGN_ORDER', {
          root: true
        });
        return
      } else {
        dispatch("app/togglePromptModal", 'UNASSIGN_ORDER', {
          root: true
        });
      }
    }

    Vue.http.post(getters.getBaseURL + "orders/unassign/", payload).then(
      (res) => {
        if (res.body.result != 0) {
          alert(res.body.error);
        } else {
          dispatch("orders/clearSelectedOrders", null, {
            root: true
          });
          dispatch("drivers/deselectDriver", null, {
            root: true
          });
        }
      },
      (res) => {
        console.log("Failed to unassign orders...");
      }
    );
  },
  cacheDirectionsDisplay({
    commit
  }, cacheData) {
    commit('cacheDirectionsDisplay', cacheData)
  },
  getOrders({
    commit,
    getters
  }) {
    var store_id = getters.getStoreID;
    var base_url = getters.getBaseURL;
    var par = {
      'store_id': store_id,
      'base_url': base_url
    };
    commit('getOrders', par);
  },
  getById({
    commit,
    getters
  }, orderId) {
    var hub_url = getters.getHubURL;
    var par = {
      'order_id': orderId,
      'hub_url': hub_url
    };
    commit('getById', par);
  },
  updateOrders({
    commit,
    getters,
    dispatch,
    rootState
  }, orders) {
    const checkSelectionPar = {
      selectedDriver: getters.getSelectedDriver,
      orders: orders,
      dispatch: dispatch
    };
    const par = {
      orders,
      isRingActivated: rootState.isRingActivated
    }
    commit('checkNewOrders', par);
    commit('checkOrdersAndDriverSelection', checkSelectionPar)
    commit('reset');
    commit('updateOrders', orders);
    commit('updateSelections', orders);
  },
  updateCurrentOrders({
    commit
  }, orders) {
    commit('updateOrders', orders);
  },
  dragOrder({
    commit
  }, order) {
    commit('dragOrder', order);
  },
  getCustomerInfo({
    commit,
    getters
  }) {
    var store_id = getters.getStoreID;
    var api_url = getters.getApiUrl;
    var par = {
      'store_id': store_id,
      'api_url': api_url
    };
    commit('getCustomerInfo', par);
  },
  clearNewOrder({
    commit
  }) {
    commit('clearNewOrder');
    commit('setNewOrderError', "");
  },
  getStoreById({
    commit,
    getters
  }) {
    var store_id = parseInt(getters.getStoreID);
    var base_url = getters.getApiUrl;
    var par = {
      'store_id': store_id,
      'base_url': base_url
    };
    commit('getStoreById', par);
  },
  getStore({
    commit,
    getters
  }) {
    var store_id = parseInt(getters.getStoreID);
    var base_url = getters.getApiUrl;
    var par = {
      'store_id': store_id,
      'base_url': base_url
    };
    commit('getStore', par);
  },
  setIsOrderSaving({
    commit
  }, isSaving){
    commit('setIsOrderSaving', isSaving)
  },
  setEditOrder({
    commit
  }, editOrder) {
    commit('setEditOrder', editOrder);
    commit('setEditOrderError', "");
  },
  newOrderPhone({
    commit
  }, phone) {
    commit('newOrderPhone', phone);
  },
  setNewOrderError({
    commit
  }, error) {
    commit('setNewOrderError', error);
  },
  setEditOrderError({
    commit
  }, error) {
    commit('setEditOrderError', error);
  },
  addOrderStatusChanging({
    commit
  }, order) {
    commit('addOrderStatusChanging', order);
  },
  removeOrderStatusChanging({
    commit
  }, order) {
    commit('removeOrderStatusChanging', order);
  },
  selectAllOrders({
    commit,
    getters,
    state,
    dispatch
  }) {
    commit('selectOrders', getters.inStoreNotAssignedOrders);
    dispatch("orders/assignTentativeRoute", null, {
      root: true
    });
  },
  toggleOrderSelection({
    commit,
    dispatch
  }, order) {
    commit('toggleOrderSelection', order);
    dispatch("orders/assignTentativeRoute", null, {
      root: true
    });
  },
  removeOrderFromSelection({
    commit
  }, order) {
    commit('removeOrderFromSelection', order);
  },
  clearSelectedOrders({
    commit
  }) {
    commit('clearSelectedOrders');
  },
  addOrderCustomerInfo({
    commit,
    getters
  }, customerInfo) {
    commit('addOrderCustomerInfo', {
      api_url: getters.getApiUrl,
      customerInfo
    });
  },
  assignTentativeRoute({
    getters,
    state,
    dispatch
  }) {
    var store_id = getters.getStoreID;
    var base_url = getters.getBaseURL;
    var selectedDriver = getters.getSelectedDriver;
    var selectedOrders = state.selectedOrders;
    const pendingOrders = (getters.pendingOrders|| []).filter(o => !o?.external_delivery_id)
    const pendingOrdersInSelectedOrders = selectedOrders.some(id => pendingOrders.some(o => o.order_id === id))

    if (!pendingOrdersInSelectedOrders && selectedDriver?.driver_id && selectedOrders) {
      var payload = {
        store_id: store_id,
        method: "POST",
        route: selectedOrders,
        driver_id: selectedDriver.driver_id,
      };
  
      // If a request is in progress, cancel it before starting a new one
      if (state.assignTentativeRouteController) {
        state.assignTentativeRouteController.cancel('Previous request canceled.');
      }
      state.assignTentativeRouteController = axios.CancelToken.source();
  
      // Make the API request using Axios to be able to cancel it
      axios.post(base_url + "route/assign-tentative/", payload, {
        cancelToken: state.assignTentativeRouteController.token
      }).then(
        (res) => {
          if (res.data.result != 0) {
            alert(res.data.error);
          }
        }
      ).catch((error) => {
        if (axios.isCancel(error)) {
          console.log('Request canceled:', error.message);
        } else {
          console.log("Failed to assign tentative route...");
        }
      });
    } else {
      dispatch("orders/clearTentativeRoute", null, { root: true });
    }
  },
  clearTentativeRoute({
    getters
  }) {
    var store_id = getters.getStoreID;
    var base_url = getters.getBaseURL;
    var payload = {
      store_id: store_id,
      method: "POST",
    };
    Vue.http.post(base_url + "route/clear-tentative/", payload).then(
      (res) => {
        if (res.body.result != 0) {
          alert(res.body.error);
        }
      },
      (res) => {
        console.log("Failed to assign tentative route...");
      }
    );
  },
  getCurrentDayOrders({
    commit,
    getters
  }, par) {
    var base_url = getters.getApiUrl
    var par = {
      'store_id': par.storeId,
      'driver_id': par.driverId == undefined || par.driverId == '' ? 0 : par.driverId,
      'base_url': base_url
    };
    commit('getCurrentDayOrders', par);
  },
  getPayRollReport({
    commit,
    getters
  }, par) {
    const api_url = getters.getApiUrl;
    par.base_url = api_url

    commit('getPayRollReport', par);
  },
  openStore({
    commit,
    getters,
    dispatch
  }, par) {
    var api_url = getters.getApiUrl;
    var par = {
      'id': 0,
      'storeId': getters.getStoreID,
      'transactionType': par.transactionType,
      'amount': parseFloat(par.amount),
      'isOpen': true,
      'api_url': api_url,
      'dispatch': dispatch
    }
    commit('openStore', par);
  },
  closeStore({
    commit,
    getters,
    dispatch
  }, par) {
    var api_url = getters.getApiUrl;
    var par = {
      'id': 0,
      'storeId': getters.getStoreID,
      'transactionType': par.transactionType,
      'amount': parseFloat(par.amount),
      'isOpen': true,
      'api_url': api_url,
      'dispatch': dispatch,
      'completePickupOrders': par.completePickupOrders
    }

    commit('closeStore', par);
  },
  getOpenDayFile({
    commit,
    getters
  }, par) {
    var par = {
      'api_url': getters.getApiUrl,
      'store_id': getters.getStoreID
    }

    commit('getOpenDayFile', par);
  },
}

// mutations
const mutations = {
  checkNewOrders(state, par) {
    const orders = par.orders;
    const isRingActivated = par.isRingActivated;
    let currentOrderIds = state.all.map(o => o.order_id);
    // Check for any new orders
    let newOrderIds = orders.map(o => o.order_id);
    let difference = newOrderIds.filter(x => !currentOrderIds.includes(x));
    if (difference.length && isRingActivated) {
      var audio = new Audio('mp3/ding.mp3'); // path to file
      audio.play().catch((error) => console.debug(error));
    }

    // Check for any new pending orders
    let newPendingOrderIds = orders.filter(o => o.pending).map(o => o.order_id);
    let pendingDifference = newPendingOrderIds.filter(x => !currentOrderIds.includes(x));
    if (pendingDifference.length && isRingActivated) {
      setTimeout(() => {
        var audio = new Audio('mp3/pending.mp3'); // path to file
        audio.play().catch((error) => console.debug(error));
      }, 15000);
    }

  },
  checkOrdersAndDriverSelection(state, par){
    const orders = par.orders;
    const selectedDriver = par.selectedDriver;
    const priorSelections = [...state.selectedOrders];

    if(priorSelections == []) return;

    // Check if the selected orders are not on the road in the old list but are on the road in the new list.
    const ordersToDeselect = priorSelections.filter(orderId => {
        const isOnRoadCurrent = state.all.some(order => order.order_id == orderId && order.time_driver_left == null);
        const isOnRoadNew = orders.some(order => order.order_id == orderId && order.time_driver_left != null);
        return isOnRoadCurrent && isOnRoadNew;
    });

    if (ordersToDeselect.length > 0) {
      ordersToDeselect.forEach(o => {
        // Deselect orders
        const index = state.selectedOrders.indexOf(o);
        if (index > -1) {
          state.selectedOrders.splice(index, 1);
        }
      })
    }

    if(selectedDriver != null){
      // Check if any order in ordersToDeselect has the driver_id equal to selectedDriverId
      const deselectDriver = ordersToDeselect.some(orderId =>
        orders.some(order => order.order_id === orderId && order.driver_id === selectedDriver.driver_id)
      );

      if(deselectDriver){
        par.dispatch("drivers/deselectDriver", null, {
          root: true
        });
      }
    }
  },
  updateOrders(state, orders) {
    state.allWithVoid.splice(0, state.allWithVoid.length);
    orders.forEach(order => {
      if (!order.isVoid) {
        state.all.push(order);
      }
      state.allWithVoid.push(order);
    });
  },
  updateSelections(state, orders) {
    var priorSelections = [...state.selectedOrders];
    for (var i in orders) {
      if (orders[i].delivery_time) {
        const index = state.selectedOrders.indexOf(orders[i].order_id);
        if (index > -1) {
          //state.selectedOrders.splice(index, 1);
        }
      }
    }
    for (var j in priorSelections) {
      if (orders.filter((o) => o.order_id == priorSelections[j]).length == 0) {
        const index = state.selectedOrders.indexOf(priorSelections[j]);
        if (index > -1) {
          state.selectedOrders.splice(index, 1);
        }
      }
    }
  },
  reset(state) {
    state.all.splice(0, state.all.length);
    state.allWithVoid.splice(0, state.allWithVoid.length);
  },
  dragOrder(state, order) {
    state.draggedOrder = order;
  },
  getOrders(state, par) {
    var payload = {
      "store_id": par.store_id,
      "method": "GET"
    }
    Vue.http.post(par.base_url + 'orders/', payload).then(res => {
      if (res.body.result != 0) {
        alert(res.body.error)
      } else {
        // Clear orders
        state.all.splice(0, state.all.length);
        state.allWithVoid.splice(0, state.allWithVoid.length);
        // Load new orders
        const orders = res.body.order_info
        orders.forEach(order => {
          if (!order.isVoid) {
            state.all.push(order);
          }
          state.allWithVoid.push(order);
        });
      }
    }, error => {
      console.log('Failed to get orders...', error);
    })
  },
  getById(state, par) {
    Vue.http.get(par.hub_url + 'Order/Get?id=' + par.order_id).then(res => {
      if (!res.body) {
        alert("something went wrong")
      } else {
        state.editOrder = res.body;
      }
    }, error => {
      console.log('Failed to get order...', error);
    })
  },
  getCustomerInfo(state, par) {
    Vue.http.get(par.api_url + "api/v1/OrderCustomers/GetOrderCustomerInfo/" + state.newOrder.phone + "/" + par.store_id)
      .then(res => {
        console.log(res)
        if (res.status == 200) {
          state.newOrder.name = res.body.customerName;
          state.newOrder.address = res.body.address;
          state.newOrder.special_instructions = res.body.deliveryInstructions 
        }
      }, error => {
        console.log('Failed to get customer info...', error);
      });
  },
  clearNewOrder(state) {
    state.newOrder = {
      ...newOrderTemplate
    };
  },
  setEditOrder(state, editOrderId) {
    let entry_date = null;
    let entry_time = null;
    let deliver_at = new Date();
    let deliver_time = new Date();
    let is_scheduled = false;
    let deliver_by_datetime = new Date();
    let deliver_datetime  = new Date();
    let entry_datetime  = new Date()
    var editOrder = state.all.find(o => o.order_id == editOrderId);
    if (editOrder) {
      const timestampEntry = editOrder.entry_time.match(/\/Date\((\d+)\)\//)[1];
      const entryDate = new Date(parseInt(timestampEntry));
      // Format the Date object as 'yyyy-MM-ddTHH:mm'
      const formattedEntryDate = entryDate.toISOString().slice(0, 16);
      entry_datetime = formattedEntryDate;

      if (editOrder.ScheduleType == "SCHEDULED") {
        const timestampDt = editOrder.DeliverAt.match(/\/Date\((\d+)\)\//)[1];
        const dateDt = new Date(parseInt(timestampDt));
        // Format the Date object as 'yyyy-MM-ddTHH:mm'
        const formattedDateDt = dateDt.toISOString().slice(0, 16);
        deliver_datetime = formattedDateDt;
        is_scheduled = true;
      }

      const tgt = editOrder.target_delivery_time_minutes;
      const entryDateTime = new Date(entry_datetime);
      entryDateTime.setMinutes(entryDateTime.getMinutes() + tgt + 10);
      const deliver_by_date = [
          entryDateTime.getFullYear(),
          ('0' + (entryDateTime.getMonth() + 1)).slice(-2),
          ('0' + entryDateTime.getDate()).slice(-2)
      ].join('-');

      const deliver_by_time = `${entryDateTime.getHours().toString().padStart(2, '0')}:${entryDateTime.getMinutes().toString().padStart(2, '0')}`;
      deliver_by_datetime = `${deliver_by_date}T${deliver_by_time}`;
    }
    state.editOrder = {
      ...editOrder,
      entry_datetime,
      is_scheduled,
      deliver_datetime,
      deliver_by_datetime
    };
  },
  cacheDirectionsDisplay(state, cacheData) {
    state.directionsDisplayCache.push(cacheData);
  },
  newOrderPhone(state, phone) {
    state.newOrder.phone = phone;
    var d = new Date();
    const entry_date = [
      d.getFullYear(),
      ('0' + (d.getMonth() + 1)).slice(-2),
      ('0' + d.getDate()).slice(-2)
    ].join('-');

    const entry_time = `${d.getHours().toString().padStart(2, '0')}:${d.getMinutes().toString().padStart(2, '0')}`;
    state.newOrder.entry_datetime = `${entry_date}T${entry_time}`;
  },
  setNewOrderError(state, error) {
    state.newOrderError = error;
  },
  setEditOrderError(state, error) {
    state.editOrderError = error;
  },
  setIsOrderSaving(state, isSaving){
    state.isOrderSaving = isSaving;
  },
  addOrderStatusChanging(state, order) {
    state.orderStatusChanging.push(order.order_id);
  },
  removeOrderStatusChanging(state, order) {
    const index = state.orderStatusChanging.indexOf(order.order_id);
    if (index > -1) {
      state.orderStatusChanging.splice(index, 1);
    }
  },
  selectOrders(state, ordersToSelect) {
    const ordersToSelectIds = ordersToSelect.map(o => o.order_id);
    const resultSet = new Set([...state.selectedOrders, ...ordersToSelectIds]);
    state.selectedOrders = Array.from(resultSet);
  },
  toggleOrderSelection(state, order) {
    const index = state.selectedOrders.indexOf(order.order_id);
    let newSelectedOrders = [...state.selectedOrders];
    if (index > -1) {
      newSelectedOrders.splice(index, 1);
    } else if (order && order.order_id) {
      newSelectedOrders.push(order.order_id);
    }
    state.selectedOrders = newSelectedOrders;
  },
  removeOrderFromSelection(state, order){
    state.selectedOrders = state.selectedOrders.filter(selectedOrderId => selectedOrderId !== order.order_id);
  },
  clearSelectedOrders(state) {
    state.selectedOrders = [];
  },  
  addOrderCustomerInfo(state, par){
    const customerInfoBody = {
      storeId: par.customerInfo.storeId,
      phoneNumber: par.customerInfo.phoneNumber,
      customerName: par.customerInfo.customerName,
      address: par.customerInfo.address,
      deliveryInstructions: par.customerInfo.deliveryInstructions
    }
    Vue.http.post(par.api_url + "api/v1/OrderCustomers/AddOrUpdateOrderCustomerInfo", customerInfoBody)    
  },
  getCurrentDayOrders(state, par) {
    if (par.driver_id) {
      let ordersByDriver = state.all.filter(o => o.driver_id === par.driver_id);
      state.currentDayOrders = [...ordersByDriver];
    }
    else {
      state.currentDayOrders = [...state.all];
    }
  },
  openStore(state, par) {
    var payload = {
      id: par.id,
      storeId: parseInt(par.storeId),
      transactionType: par.transactionType,
      amount: par.amount,
      isOpen: true
    };
    Vue.http.post(par.api_url + "api/v1/stores/OpenStore", payload).then(
      (res) => {
        par.dispatch('app/togglePromptModal', 'DAY_OPEN', {root: true})
        state.store.isOpen = true;
      },
      (res) => {
        console.log("failed");
        alert(res.body)
      }
    );
  },
  closeStore(state, par) {
    var payload = {
      id: par.id,
      storeId: parseInt(par.storeId),
      transactionType: par.transactionType,
      amount: par.amount,
      isOpen: true,
      completePickupOrders: par.completePickupOrders
    };
    Vue.http.post(par.api_url + "api/v1/stores/CloseStore", payload).then(
      (res) => {
        par.dispatch('app/togglePromptModal', 'DAY_CLOSED', {root: true})
        state.store.isOpen = false;
        state.currentDayOrders = [];
        state.driversPayRollReport = [];
        par.dispatch("getOrders");
        par.dispatch("getStore")
        state.openDayFile = null;
      },
      (res) => {
        par.dispatch('app/togglePromptModal', 'DAY_CLOSED', {root: true})
        if(res.bodyText == 'Drivers must not have a route prior to closing day.') {
          par.dispatch('app/togglePromptModal', 'CLOSE_DAY_DRIVER_LEFT', {root: true})
        }
        else if(res.bodyText == 'Drivers must not have an outstanding balance, close out all drivers prior to continuing.'){
          par.dispatch('app/togglePromptModal', 'CLOSE_DAY_OUTSTANDING_BALANCE', {root: true})
        } 
        
        // else {
        //   par.dispatch('app/togglePromptModal', 'CLOSE_DAY_ERROR', {root: true})
        // }

      }
    );
  },
  getOpenDayFile(state, par) {
    Vue.http.get(par.api_url + 'Api/V1/Stores/GetOpenDayFile?storeId=' + par.store_id).then(res => {
      if (res.body == null) {
        alert(res.body.error)
      } else {
        state.openDayFile = res.body;
      }
    }, error => {
      state.openDayFile = null;
      console.log('Failed to get open day file...', error);
    })
  },
  getStore(state, par) {
    Vue.http.get(par.base_url + 'Api/V1/Stores/GetStoreById?storeId=' + par.store_id).then(res => {
      if (res.body == "") {
        state.store = null;
      } else {
        state.store = res.body;
      }
    }, error => {
      console.log('Failed to get store...', error);
    })
  },
  getPayRollReport(state, par) {
    Vue.http.post(par.base_url + "Api/V1/Drivers/GetPayrollReportData", par).then(res => {
      if (res.body == null) {
        alert(res.body.error)
      } else {
        state.driversPayRollReport = res.body;
      }
    }, error => {
      console.log('Failed to get orders...', error);
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}