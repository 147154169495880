<template>
  <div class="card">
    <div class="card-content">
      <div class="columns">
        <div class="column is-1">
          <a
            class="button ready-button is-link"
            v-on:click="changeDateToToday()"
            >Today</a
          >
        </div>

        <div class="column is-3 selectWrap">
          <select
            id="dateSelect"
            @change="onCloseDateChange($event)"
            style="width: 100%"
          >
            <option value="">Select Closed Day</option>
            <option v-for="cs in closedDates" :key="cs.dayId" :value="cs.dayId">
              {{
                "DayId: " +
                cs.dayId +
                ", " +
                formatDateTime(cs.openDateTime, true) +
                " - " +
                formatDateTime(cs.closeDateTime, true)
              }}
            </option>
          </select>
        </div>

        <!-- 
        <div class="column is-4">
          <input class="input" type="datetime-local" v-model="date"/>
        </div> -->

        <div class="column is-3 is-pulled-left">
          <input class="input" type="datetime-local" v-model="computedDate" />
        </div>

        <div class="column is-3 is-pulled-left">
          <input class="input" type="datetime-local" v-model="computedDateEnd" />
        </div>

        <div class="column is-2 display-column-dropdown">
            <columnsDropdown
                :columns="columns"
                :isLarge="true"
                @update-columns="handleColumnsUpdate"
            />
        </div>
      </div>
      <div class="field full-width">
        <p class="control has-icons-left has-icons-right">
          <input
            class="input"
            type="text"
            placeholder="Search by Name, Phone number, Address or Ticket #"
            v-model="search"
          />
          <span class="icon is-small is-left">
            <i class="fa fa-search"></i>
          </span>
        </p>
      </div>
      <div class="columns table-headings is-size-7">
        <div class="column is-1 name-column has-text-left move-text-up">
          NAME
        </div>
        <div class="column is-2 order-number-column has-text-left move-text-up">
          SOURCE
        </div>
        <div class="column is-2 order-number-column has-text-left move-text-up">
          #
        </div>
        <div class="column new-address-column has-text-left move-text-up">
          ADDRESS
        </div>
        <div class="column is-2 new-time-column has-text-left move-text-up">
          RECEIVED
        </div>
        <div class="column is-2 new-time-column has-text-left move-text-up" v-if="!retailOptimization">
          COMPLETED
        </div>
        <div class="column time-column has-text-centered" v-if="!retailOptimization && isColumnVisible('TUD')">
          <div class="tooltip" v-show="tooltips[3]">
            Time until delivery
            <div class="tooltip-point"></div>
          </div>
          <a
            class="button is-small has-text-weight-medium"
            v-on:click="showTool(3)"
            >TUD</a
          >
        </div>
        <div class="column time-column has-text-centered" v-if="isColumnVisible('TOR')">
          <div class="tooltip" v-show="tooltips[2]">
            Time on the road
            <div class="tooltip-point"></div>
          </div>
          <a
            class="button is-small has-text-weight-medium"
            v-on:click="showTool(2)"
            >TOR</a
          >
        </div>
        <div class="column time-column has-text-centered" v-if="!retailOptimization && isColumnVisible('TGT')">
          <div class="tooltip" v-show="tooltips[0]">
            Target time
            <div class="tooltip-point"></div>
          </div>
          <a
            class="button is-small has-text-weight-medium"
            v-on:click="showTool(0)"
            @mousedown="startClick" 
            @mouseup="cancelClick"
            @mouseleave="cancelClick"
            >TGT</a
          >
        </div>
        <div class="column time-column has-text-centered" v-if="isColumnVisible('EDT')">
          <div class="tooltip" v-show="tooltips[1]">
            Estimated delivery time
            <div class="tooltip-point"></div>
          </div>
          <a
            class="button is-small has-text-weight-medium"
            v-on:click="showTool(1)"
            @mousedown="startClick" 
            @mouseup="cancelClick"
            @mouseleave="cancelClick"
            >EDT</a
          >
        </div>
        <div class="column time-column has-text-centered" v-if="isColumnVisible('ADT')">
          <div class="tooltip" v-show="tooltips[4]">
            Actual delivery time
            <div class="tooltip-point"></div>
          </div>
          <a
            class="button is-small has-text-weight-medium"
            v-on:click="showTool(4)"
            @mousedown="startClick"
            @mouseup="endClick('ADT')"
            @mouseleave="endClick('ADT')"
            >ADT</a
          >
        </div>
        <div class="column is-2 driver-column has-text-left move-text-up">
          DRIVER
        </div>
        <div class="column is-2 driver-column has-text-left move-text-up">
          STATUS
        </div>
        <div class="column time-column has-text-centered">
          <div class="tooltip" v-show="tooltips[5]">
            Proof of Delivery
            <div class="tooltip-point"></div>
          </div>
          <a
            class="button is-small has-text-weight-medium"
            v-on:click="showTool(5)"
            >POD
          </a>
        </div>
        <div class="column time-column has-text-centered">
        </div>
      </div>

      <div class="has-text-centered" v-if="filteredOrders.length == 0">
        <span class="no-orders-text">No Orders Found</span>
      </div>

      <div class="order-details-area">
        <div
          class="columns table-rows is-size-7"
          v-for="o in filteredOrders"
          v-bind:key="o.order_id"
          v-on:click="$emit('toggleOrderSelection', o)"
        >
          <!-- NAME -->
          <div class="column is-1 name-column has-text-left text-bold">
            {{ getShortName(o.customer_name).toUpperCase() }}
          </div>
          <!-- SOURCE -->
          <div class="column is-2 order-number-column has-text-left">
            <img
              class="img source-logo"
              :src="getOrderSourceLogo(o.OrderSource)"
            />
          </div>
          <!-- ID -->
          <div class="column is-2 order-number-column has-text-left">
            {{
              o.source_id ? getShortOrderNumber(o.source_id) : o.order_number
            }}
          </div>
          <!-- ADDRESS -->
          <div class="column new-address-column has-text-left">
            {{ o.address.toUpperCase() }}
          </div>
          <!-- RECIEVED -->
          <div class="column is-2 new-time-column has-text-left text-bold">
            {{ formattedEntryTime(o) }}
          </div>
          <!-- DELIVERED -->
          <div class="column is-2 new-time-column has-text-left text-bold"  v-if="!retailOptimization">
            {{
              o.delivery_time || o.ExternalEstimatedTimeToDeliver || o.PickupTime
                ? new Date(
                    o.delivery_time ||
                      parseInt(String(o.ExternalEstimatedTimeToDeliver).substr(6)) || o.PickupTime
                  ).toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true,
                  })
                : o.isReturn && o.ReturnedTime ? 
                  new Date(o.ReturnedTime).toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true,
                  })
                : "--"
            }}
          </div>
          <!-- TUD -->
          <div class="column time-column has-text-centered" v-if="!retailOptimization && isColumnVisible('TUD')">
            <span
              v-html="
                o.estimated_delivery_time
                  ? -elapsedTime(o.estimated_delivery_time)
                  : o.delivery_time
                  ? '<span class=\'green-color has-text-weight-bold\'>D</span>'
                  : o.isReturn 
                  ? '<span class=\'will-be-late has-text-weight-bold\'>R</span>'
                  : '--'
              "
            ></span>
          </div>
          <!-- TOR -->
          <div class="column time-column has-text-centered" v-if="isColumnVisible('TOR')">
            {{ o.time_on_road && o.time_on_road != -1 ? o.time_on_road : "--" }}
          </div>
          <!-- TGT -->
          <div class="column time-column has-text-centered" v-if="!retailOptimization && isColumnVisible('TGT')">
            {{ displayTime("TGT", o.target_delivery_time_minutes) }}
          </div>
          <!-- EDT -->
          <div
            v-if="isColumnVisible('EDT')"
            class="column time-column has-text-weight-bold has-text-centered"
            :class=" retailOptimization ? 'green-color has-text-weight-bold' :
              o.target_delivery_time_minutes + 10 <
              (o.delivery_time && o.expected_time_minutes != null &&
              (o.external_delivery_id == null || o.external_delivery_id == '')
                ? o.expected_time_minutes
                : getTimeDiffInMinutes(
                    o.entry_time,
                    o.ExternalEstimatedTimeToDeliver ||
                      o.estimated_delivery_time
                  ))
                ? ' will-be-late has-text-weight-bold '
                : o.target_delivery_time_minutes <
                  (o.delivery_time && o.expected_time_minutes != null &&
                  (o.external_delivery_id == null ||
                    o.external_delivery_id == '')
                    ? o.expected_time_minutes
                    : getTimeDiffInMinutes(
                        o.entry_time,
                        o.ExternalEstimatedTimeToDeliver ||
                          o.estimated_delivery_time
                      ))
                ? 'orange-color has-text-weight-bold'
                : 'green-color has-text-weight-bold'
            "
          >
            {{ retailOptimization || showActualTime("EDT")
                    ? (o.expected_time_minutes != null && (o.external_delivery_id == null || o.external_delivery_id == '') 
                        ? formatTimeFromDateAndMinutes(o.entry_time, o.expected_time_minutes) 
                        : (o.ExternalEstimatedTimeToDeliver 
                            ? formatTime(o.ExternalEstimatedTimeToDeliver) 
                            : "--")) 
                    : (o.expected_time_minutes != null && (o.external_delivery_id == null || o.external_delivery_id == '') 
                        ? o.expected_time_minutes 
                        : (o.ExternalEstimatedTimeToDeliver 
                            ? -stringTimeDiff(o.entry_time, o.ExternalEstimatedTimeToDeliver) 
                            : "--"))
                    }}
          </div>
          <!-- ADT -->
          <div
            v-if="isColumnVisible('ADT')"
            class="column time-column has-text-weight-bold has-text-centered"
            :class=" retailOptimization ? 'green-color has-text-weight-bold' :
              o.target_delivery_time_minutes + 10 <
              getTimeDiffInMinutes(o.entry_time, o.delivery_time)
                ? ' will-be-late has-text-weight-bold '
                : o.target_delivery_time_minutes <
                  getTimeDiffInMinutes(o.entry_time, o.delivery_time)
                ? 'orange-color has-text-weight-bold'
                : 'green-color has-text-weight-bold'
            "
          >
          {{ o.delivery_time ? showActualTime("ADT") ? 
                formatTimeFromDateAndMinutes(o.delivery_time, 0) : 
                 getTimeDiffInMinutes(o.entry_time, o.delivery_time, true) : '--' }}
          </div>
          <!-- Driver -->
          <div class="column is-2 driver-column has-text-left">
            {{
              o.OrderType == "OutSourced" && o.ExternalDeliverySource ? 
                o.ExternalDeliverySource.toUpperCase() : 
              o.driver_id
                ? getDriverShortName(
                    (getAllDrivers.find((d) => d.driver_id == o.driver_id) || {})
                      .driver_name,
                    (getAllDrivers.find((d) => d.driver_id == o.driver_id) || {})
                      .DriverLastName
                  ).toUpperCase()
                :  "--"
            }}
          </div>
          <!-- Status -->
          <div
            class="column is-2 driver-column has-text-centered"
            :class=" 
              o.external_delivery_status || o.delivery_time
                ? 'green-color has-text-weight-bold'
                : o.isReturn
                ? 'will-be-late has-text-weight-bold'
                : o.is_ready_to_deliver
                ? 'green-color has-text-weight-bold'
                : ''
            "
          >
            {{
              o.isVoid ? 'VOID' : 
              o.external_delivery_status
                ? o.external_delivery_status.toUpperCase()
                : o.delivery_time
                ? "DELIVERED"
                : o.PickupTime 
                ? "PICKED UP"
                : o.isReturn
                ? "RETURNED"
                : o.is_ready_to_deliver && (getAllDrivers.find((d) => d.driver_id == o.driver_id) || {}).time_driver_left
                ? "ON THE WAY"
                : o.is_ready_to_deliver
                ? "READY"
                : "NOT READY"
            }}
          </div>
          <div class="column time-column has-text-centered">
            <a class="button is-outlined is-info is-small" v-if="o.isProofOfDeliveryUploaded && o.delivery_time" @click="showImage(o.order_id)" >
              <i class="fa fa-camera"></i>
            </a>
          </div>
          <div class="column time-column has-text-centered">
            <a v-if="o.isVoid" class="button is-outlined is-primary is-small" @click="reactivateOrder(o)" >
              <i class="fa fa-refresh" title="Reactivate"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div v-if="selectedImage" class="modal" @click="closeImage">
      <img :src="selectedImage" alt="Order Image" class="order-image" />
    </div>
  </div>
</template>

<script>
var timeFunctions = require("../../js/timeFunctions.js");
var orderFunctions = require("../../js/orderFunctions.js");
var columnsDropdown = require("../common/columns_dropdown.vue").default;
import moment from "moment";
import Vue from "vue";
import { mounted } from "../address_textbox_generic.vue";

export default {
  components: {columnsDropdown},
  beforeMount() {
    this.getClosedDates();
  },
  data: function () {
    return {
      api_url: this.$store.state.api_url,
      base_url: this.$store.state.base_url,
      store_id: this.$store.state.user.store_id,
      orders: [],
      search: "",
      date: null,
      dateEnd: null,
      closedDates: [],
      selectedDayId: "",
      tooltips: [false, false, false, false, false, false],
      chosenDayId: null,
      dateChangedManually: false,
      selectedImage: null,
      clickStartTime: null,
      clickDuration: 1000, // 1 second,
      clickTimeout: null,
      columnsPageName: "allOrdersPanel",
      columns: [
          { name: "TUD", visible: true, showActualTime: false },
          { name: "TOR", visible: true, showActualTime: false },
          { name: "TGT", visible: true, showActualTime: false },
          { name: "EDT", visible: true, showActualTime: false },
          { name: "ADT", visible: true, showActualTime: false },
      ]
    };
  },
  filters: {},
  mounted(){
    this.getAllOrders()
  },
  computed: {
    retailOptimization: function () {
        return this.$store.getters["orders/getStore"]['retailOptimization']
    },
    computedDate: {
      get() {
        return this.date;
      },
      set(newValue) {
        this.dateChangedManually = true;
        this.date = newValue;
      }
    },
    computedDateEnd: {
      get() {
        return this.dateEnd;
      },
      set(newValue) {
        this.dateChangedManually = true;
        this.dateEnd = newValue;
      }
    },
    getAllDrivers: function () {
      return this.$store.getters["drivers/getAllDrivers"];
    },
    getCurrentOrders: function () {
      return this.$store.getters["orders/getAllOrdersWithVoid"];
    },
    filteredOrders: function () {
      return this.orders.filter(
        (o) =>
          o.phone.indexOf(this.search) >= 0 ||
          o.order_number.toString().indexOf(this.search) >= 0 ||
          o.customer_name.toLowerCase().indexOf(this.search.toLowerCase()) >=
            0 ||
          o.address.toLowerCase().indexOf(this.search.toLowerCase()) >= 0
      );
    },
    openDayFile() {
      return this.$store.state.orders.openDayFile;
    },
    startAndEndDate() {
      return [this.date, this.dateEnd];
    },
  },
  methods: {
    handleColumnsUpdate(updatedColumns) {
        this.columns = updatedColumns;
    },
    addMinutesToCurrentTime: (a) => timeFunctions.addMinutesToCurrentTime(a),
    toggleShowActualTime() {
        if (this.columns.length === 0) return;
        const newValue = !this.columns[0].showActualTime;
        this.columns.forEach(col => {
            col.showActualTime = newValue;
        });
    },
    startClick() {
        this.clickTimeout = setTimeout(() => {
            this.toggleShowActualTime();
        }, this.clickDuration);
    },
    cancelClick() {
        if (this.clickTimeout) {
            clearTimeout(this.clickTimeout);
            this.clickTimeout = null;
        }
    },
    showActualTime(columnName) {
        const column = this.columns.find(({ name }) => name === columnName);
        return column ? column.showActualTime : false;
    },
    isColumnVisible(columnName) {
        const column = this.columns.find(({ name }) => name === columnName);
        return column ? column.visible : false;
    },
    displayTime(columnName, time) {
        if(this.showActualTime(columnName)) return this.addMinutesToCurrentTime(time)
        return time;
    },
    formatTimeFromDateAndMinutes(timestamp, minutesToAdd) {
      const date = new Date(timestamp);
      date.setMinutes(date.getMinutes() + minutesToAdd)

      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0'); 

      return `${hours}:${minutes}`; 
    },
    getMimeType(base64String) {
        const imageSignatures = {
            '/9j/': 'image/jpeg',
            'iVBORw': 'image/png',
        };

        for (const signature in imageSignatures) {
            if (base64String.startsWith(signature)) {
                return imageSignatures[signature];
            }
        }

        return 'application/octet-stream';
    },
    base64StringToObjectURL(base64String) {
      const mimeType = this.getMimeType(base64String);
      const byteCharacters = atob(base64String);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);

      const blob = new Blob([byteArray], { type: mimeType });

      const objectURL = URL.createObjectURL(blob);

      return objectURL;
    },
    showImage(orderId) {
      const payload = {
        storeId: this.store_id,
        orderId
      }
      Vue.http
        .post(
          this.api_url + "Api/V1/Orders/GetProofOfDelivery", payload
        )
        .then(
          (res) => {
            console.log("ress", res)
            this.selectedImage = this.base64StringToObjectURL(res.body.proofOfDeliveryPhoto);
          },
          (error) => {
            console.error("Failed to get open day file...", error);
          }
        );
    },
    closeImage() {
      this.selectedImage = null;
    },
    handleDateChange() {
      this.dateChangedManually = true;
    },
    sortOrders(){
      // delivery/outsourced
      let deliveryOrders = this.orders.filter(order => order.OrderType != "Pickup");
      let pickupOrders = this.orders.filter(order => order.OrderType == "Pickup");

      deliveryOrders = deliveryOrders.sort((a, b) => b.entry_time - a.entry_time);
      pickupOrders = pickupOrders.sort((a, b) => b.entry_time - a.entry_time);

      const sortedOrders = deliveryOrders.concat(pickupOrders);
      this.orders = sortedOrders;
    },
    formattedEntryTime(o) {
      if (!o.entry_time) {
        return "--";
      }
      
      const entryDate = new Date(o.entry_time);
      if (isNaN(entryDate.getTime())) {
        return "--"; // Invalid date
      }
      
      return entryDate.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", hour12: true });
    },
    getAllOrders() {
      const allOrdersArray = JSON.parse(JSON.stringify(this.getCurrentOrders));

      let orders = allOrdersArray.map((o) => {
          return {
            phone: "",
            OrderSource: o.OrderSource != null ? o.OrderSource : "",
            customer_name: o.customer_name,
            driver_id: o.driver_id,
            driver_name: o.driver_name,
            entry_time: this.convertToTimestamp(this.formatDateTimeForInput(o.entry_time)),
            order_id: o.order_id,
            order_number: o.order_number,
            estimated_delivery_time: 0,
            order_status: o.order_status,
            address: o.address,
            delivery_time: this.convertToTimestamp(this.formatDateTimeForInput(o.delivery_time)),
            OrderType: o.OrderType,
            isReturn: o.is_return,
            source_id: o.source_id,
            target_delivery_time_minutes: o.target_delivery_time_minutes,
            time_on_road: o.time_on_road,
            ExternalEstimatedTimeToDeliver: this.convertToTimestamp(
              this.formatDateTimeForInput(o.ExternalEstimatedTimeToDeliver)
            ),
            expected_time_minutes: o.expected_time_minutes,
            ReturnedTime: this.convertToTimestamp(this.formatDateTimeForInput(o.ReturnedTime)),
            ExternalDeliverySource: o.external_delivery_source,
            is_ready_to_deliver: o.is_ready_to_deliver,
            PickupTime: this.convertToTimestamp(this.formatDateTimeForInput(o.PickupTime)),
            isVoid: o.isVoid,
            isProofOfDeliveryUploaded: o.IsProofOfDeliveryUploaded
          };
        }
      )     
      this.orders = orders;
      this.sortOrders();
    },
    convertToTimestamp(inputDateStr) {
      if (inputDateStr) {
        const date = new Date(inputDateStr);
        const timestamp = date.getTime();
        return timestamp;
      }
      return "";
    },
    getTimeDiffInMinutes(startTime, endTime, checkRetail) {
      if (startTime && endTime) {
        if(this.retailOptimization && checkRetail) {
          const date = new Date(endTime);

          const hours = String(date.getHours()).padStart(2, '0');
          const minutes = String(date.getMinutes()).padStart(2, '0');

          return `${hours}:${minutes}`;
        }

        const start = isNaN(startTime)
          ? new Date(parseInt(startTime.substr(6)))
          : new Date(parseInt(startTime));
        const end = isNaN(endTime)
          ? new Date(parseInt(endTime.substr(6)))
          : new Date(parseInt(endTime));
        const diffInMilliseconds = Math.abs(end - start);
        const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));

        return diffInMinutes;
      }
      return 0;
    },
    showTool(a) {
      for (var i = 0; i < this.tooltips.length; i++) {
        this.$set(this.tooltips, i, false);
      }
      this.$set(this.tooltips, a, true);
      setTimeout(() => this.$set(this.tooltips, a, false), 5000);
    },
    elapsedTime: (a) => timeFunctions.elapsedTime(a),
    stringTimeDiff: (a, b) => timeFunctions.stringTimeDiff(a, b),
    formatDateTime: (value, withTime) =>
      timeFunctions.formatDateTime(value, withTime),
    formatDateTimeForInput: (value) => moment(value).format("YYYY-MM-DDTkk:mm"),
    shortAddress: (address) => orderFunctions.getShortAddress(address),
    getShortName: (name) => orderFunctions.getShortName(name),
    getDriverShortName: (fname, lname, limitFirstName) =>
      orderFunctions.getDriverShortName(fname, lname, limitFirstName),
    getShortOrderNumber: (orderNumber) =>
      orderFunctions.getShortOrderNumber(orderNumber),
    setPanel: function (panel) {
      this.$store.dispatch("app/setPanel", panel);
    },
    getOrderSourceLogo: (source) => orderFunctions.getOrderSourceLogo(source),
    getOrdersByDate: function () {
      const orderCopy = JSON.parse(JSON.stringify(this.getCurrentOrders));
      const uniqueOrders = new Set(this.orders.map((order) => order.order_id));

      orderCopy.forEach((order) => {
        if (
          moment(order.entry_time) < moment(this.dateEnd) &&
          moment(order.entry_time) > moment(this.date)
        ) {
          if (!uniqueOrders.has(order.order_id)) {
            order.entry_time = parseInt(order.entry_time.substr(6));
            order.delivery_time
              ? (order.delivery_time = parseInt(order.delivery_time.substr(6)))
              : "";
            order.estimated_delivery_time
              ? (order.estimated_delivery_time = parseInt(
                  order.estimated_delivery_time.substr(6)
                ))
              : "";
            this.orders.push(order);
            uniqueOrders.add(order.order_id);
          }
        }
      });
      this.sortOrders();
    },
    //get historical orders
    searchHistoricalOrders: function () {
      const formatDate = (date) => moment(date).format("YYYY-MM-DDTkk:mm");

      const getApiCallAndBody = () => {
        if (this.dateChangedManually) {
          return {
            call: "SearchHistoricalOrders",
            body: {
              storeId: this.store_id,
              startDate: formatDate(this.date),
              endDate: formatDate(this.dateEnd),
            },
          };
        } else {
          return {
            call: "GetHistoricalOrdersByDayFileId",
            body: {
              storeId: this.store_id,
              dayFileId: this.chosenDayId,
            },
          };
        }
      };

      const { call, body } = getApiCallAndBody();

      this.$http
        .post(this.api_url + "Api/V1/Orders/" + call, body)
        .then(
          (res) => {
            let histOrders = res.body.map((o) => {
              return {
                phone: "",
                OrderSource: o.orderSource != null ? o.orderSource : "",
                customer_name: o.customerName,
                driver_id: o.driverId,
                driver_name: o.driverName,
                entry_time: this.convertToTimestamp(o.entryTime),
                order_id: o.orderId,
                order_number: o.orderNumber,
                estimated_delivery_time: 0,
                order_status: o.orderStatus,
                address: o.address,
                delivery_time: this.convertToTimestamp(o.deliveryTime),
                OrderType: o.orderType,
                isReturn: o.isReturn,
                source_id: o.sourceId,
                target_delivery_time_minutes: o.targetDeliveryTimeMinutes,
                time_on_road: o.timeOnRoad,
                ExternalEstimatedTimeToDeliver: this.convertToTimestamp(
                  o.externalEstimatedTimeToDeliver
                ),
                expected_time_minutes: o.expectedTimeMinutes,
                ReturnedTime: this.convertToTimestamp(
                  o.returnedTime
                ),
                ExternalDeliverySource: o.externalDeliverySource,
                PickupTime: this.convertToTimestamp(o.pickupTime),
                isVoid: o.isVoid,
                isProofOfDeliveryUploaded: o.isProofOfDeliveryUploaded
              };
            });

            if (histOrders.length > 0) {
              histOrders.forEach((order) => {
                var found = false;
                this.orders.forEach((o) => {
                  if (o.order_number === order.order_number) {
                    found = true;
                  }
                });

                if (!found) this.orders.push(order);
              });
            }

            this.sortOrders();
          },
          (res) => {
            console.error("Failed to get historical...", res.body);
          }
        );
    },
    changeDateToToday: function () {
      this.dateEnd = null;
      this.date  = null;
      this.closedDates = null;
      this.getAllOrders()
      this.getClosedDates()
    },
    getClosedDates: function () {
      const api_url = this.$store.getters["orders/getApiUrl"];

      Vue.http
        .get(
          api_url + "Api/V1/Stores/GetClosedDayFiles?storeId=" + this.store_id
        )
        .then(
          (res) => {
            if (res.body == null) {
              console.log("Error", res.body.error);
            } else {
              this.closedDates = res.body;
              // this.changeDateToToday();
            }
          },
          (error) => {
            console.error("Failed to get open day file...", error);
          }
        );
    },
    onCloseDateChange(event) {
      this.selectedDayId = event.target.value;
      if (this.selectedDayId) {
        this.dateChangedManually = false;
        const chosenDay = this.closedDates.find(
          (d) => d.dayId == event.target.value
        );
        this.chosenDayId = event.target.value 
        this.date = moment(chosenDay.openDateTime).format("YYYY-MM-DDTkk:mm");
        this.dateEnd = moment(chosenDay.closeDateTime).format(
          "YYYY-MM-DDTkk:mm"
        );
      } else {
        this.changeDateToToday();
      }
    },
    reactivateOrder: function(order) {
      if (order) {
        this.$store.dispatch("orders/reactivateOrder", order.order_id)
          .then(() => {
            const updatedOrder = {
              ...order, 
              isVoid: false
            }
            if(!this.chosenDayId) {
              this.orders = this.orders.map(o => 
                o.order_id === updatedOrder.order_id ? updatedOrder : o
              );
            }
            else {
              this.orders = this.orders.filter(o => 
                o.order_id != updatedOrder.order_id
              );
            }
          })
          .catch(error => {
            console.error("Error reactivating order:", error);
          });
      } 
    },
    formatTime: (a) => timeFunctions.formatTime(a),
  },
  created: function () { 
        // Load columns from localStorage, or use defaults if none exist
        const storedColumns = localStorage.getItem(this.columnsPageName);
        this.columns = storedColumns ? JSON.parse(storedColumns) : this.columns
    },
  watch: {
    startAndEndDate(oldValues, newValues) {
      if (oldValues[0] != null && oldValues[1] != null && (newValues[0] !== oldValues[0] || newValues[1] !== oldValues[1])) {
        this.orders = [];
        this.getOrdersByDate();
        this.searchHistoricalOrders();
      }
    },
    tooltips() {},
    columns: {
        handler(newColumns) {
            localStorage.setItem(this.columnsPageName, JSON.stringify(newColumns));
        },
        deep: true 
    }
  },
};
</script>

<style scoped>
.no-orders-text {
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}

.order-details-area {
  padding-top: 12px;
  max-height: calc(100vh - 340px);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 12px;
}

.name-column {
  margin-left: 0.5em;
  max-width: 5rem;
}
.text-red {
  color: #cc0f35;
}

.selectWrap,
.labelText {
  display: flex;
  align-items: center;
  justify-content: end;
}

.selectWrap select {
  background-color: white;
  border: #b5b5b5 1px solid;
  padding: 9px;
  border-radius: 4px;
}

.middlePanel .card {
  overflow: hidden !important;
}

.reactivate-button{
  margin-top: 2px;
  margin-bottom: 2px;
}

/* Modal Styles */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
}

.order-image {
  max-width: 80%;
  max-height: 80%;
}

.empty{
  background-color: #fff;
  border-color: #dbdbdb;
  border-width: 1px;
  color: #363636;
  cursor: pointer;
  justify-content: center;
  padding-bottom: calc(.5em - 1px);
  padding-left: 1em;
  padding-right: 1em;
  padding-top: calc(.5em - 1px);
  text-align: center;
  white-space: nowrap;

}
</style>