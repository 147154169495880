<template>
    <div class="order-detail-page">
        <!-- On The Way Orders -->
        <div class="columns table-headings is-size-7">
            <div class="column is-1 name-column has-text-left move-text-up">NAME</div>
            <div class="column is-1 order-number-column has-text-left move-text-up">SOURCE</div>
            <div class="column is-1 order-number-column has-text-left move-text-up">#</div>
            <div class="column address-column has-text-left move-text-up">ADDRESS</div>
            <div class="column time-column has-text-centered" v-if="!retailOptimization && isColumnVisible('TUD')">
                <div class="tooltip" v-show="tooltips[1]">
                    Time until delivery
                    <div class="tooltip-point"></div>
                </div>
                <a class="button is-small has-text-weight-medium" v-on:click="showTool(1)">TUD</a>
            </div>
            <div class="column time-column has-text-centered" v-if="!retailOptimization && isColumnVisible('TGT')">
                <div class="tooltip" v-show="tooltips[0]">
                    Target time
                    <div class="tooltip-point"></div>
                </div>
                <a class="button is-small has-text-weight-medium" v-on:click="showTool(0)" @mousedown="startClick" 
                @mouseup="cancelClick" @mouseleave="cancelClick">TGT</a>
            </div>
            <div class="column time-column has-text-centered" v-if="isColumnVisible('EDT')">
                <div class="tooltip" v-show="tooltips[2]">
                Estimated delivery time
                <div class="tooltip-point"></div>
            </div>
            <a class="button is-small has-text-weight-medium" v-on:click="showTool(2)" @mousedown="startClick" 
                @mouseup="cancelClick" @mouseleave="cancelClick">EDT</a>
            </div>
            <div class="column is-1 driver-column has-text-left move-text-up">DRIVER</div>
            <div class="column is-1 driver-column has-text-left move-text-up">REF ID</div>
            <div class="column is-1 button-column has-text-centered"></div>
            <div class="column is-1 display-column-dropdown">
                <columnsDropdown
                    :columns="columns"
                    @update-columns="handleColumnsUpdate"
                />
            </div>
        </div>

        <div class="order-details-area" v-if="onTheWayOrders.length">
            <div class="columns table-rows is-size-7" v-for="o in onTheWayOrders" v-bind:key="o.order_id">

                <div class="column is-1 name-column has-text-left text-bold">
                    {{ getShortName(o.customer_name).toUpperCase() }}
                </div>
                <div class="column is-1 order-number-column has-text-left">
                    <img class="img source-logo" :src="getOrderSourceLogo(o.OrderSource)" />
                </div>
                <div class="column is-1 order-number-column has-text-left">
                    {{ o.source_id  ? getShortOrderNumber(o.source_id ) : o.order_number }}
                </div>
                <div class="column address-column has-text-left">{{ (o.address).toUpperCase() }}</div>
                <div class="column time-column has-text-centered" v-if="!retailOptimization">
                    {{  o.time_on_road != -1 ? o.time_on_road : "--" }}
                </div>
                <div class="column time-column has-text-centered" v-if="!retailOptimization && isColumnVisible('TGT')">
                    {{ displayTime("TGT", o.target_delivery_time_minutes) }}
                </div>
                <div v-if="isColumnVisible('EDT')" class="column time-column has-text-weight-bold has-text-centered" 
                    :class=" retailOptimization ? 'green-color has-text-weight-bold' : o.ExternalEstimatedTimeToDeliver || o.estimated_delivery_time ?
                        o.target_delivery_time_minutes + 10 < -stringTimeDiff(o.entry_time, o.ExternalEstimatedTimeToDeliver || o.estimated_delivery_time)
                        ? 
                        ' will-be-late has-text-weight-bold '
                        : 
                        o.target_delivery_time_minutes < -stringTimeDiff(o.entry_time, o.ExternalEstimatedTimeToDeliver || o.estimated_delivery_time)
                        ? 
                        'orange-color has-text-weight-bold' 
                        : 
                        'green-color has-text-weight-bold' : ''
                    "
                >
                    {{ retailOptimization || showActualTime("EDT")
                        ? (o.estimated_delivery_time || o.ExternalEstimatedTimeToDeliver 
                            ? formatTime(o.estimated_delivery_time || o.ExternalEstimatedTimeToDeliver) 
                            : '--') 
                        : (o.estimated_delivery_time || o.ExternalEstimatedTimeToDeliver 
                            ? -stringTimeDiff(o.entry_time, o.estimated_delivery_time || o.ExternalEstimatedTimeToDeliver) 
                            : '--') 
                    }}
                </div>
                <div class="column is-1 driver-column has-text-left">
                    {{
                        o.driver_id ? driverShortName(o.driver_id) :
                        o.external_delivery_source.toUpperCase()
                    }}
                </div>
                <div class="column is-1 driver-column has-text-left move-text-up">
                    {{ o.external_delivery_id ? getShortOrderNumber(o.external_delivery_id ) : '--' }}
                </div>
                <div class="column is-1 button-column has-text-centered">
                    <a class="button is-outlined is-info is-small"
                        v-on:click="handleOrderDelivery(o)"
                        :disabled="isOrderBeingDelivered[o.order_id] || (o.external_delivery_id !== null && o.external_delivery_id !== '')">
                        <span v-if="!isOrderBeingDelivered[o.order_id]" class="fa fa-exchange"></span>
                        <span v-else class="fa fa-spinner fa-spin"></span>
                    </a>
                </div>
                <div class="column is-1 button-column has-text-centered" v-if="o.external_delivery_id && o.externalDeliveryTrackingUrl">
                    <a class="button is-outlined is-info is-small"
                        v-on:click="openTrackingPage(o.externalDeliveryTrackingUrl)">
                        <span class="fa fa-location-arrow"></span>
                    </a>
                </div>
                <div class="column is-1"></div>
            </div>
        </div>
    </div>
</template>

<script>
var timeFunctions = require("../../js/timeFunctions.js");
var orderFunctions = require("../../js/orderFunctions.js");
var columnsDropdown = require("../common/columns_dropdown.vue").default;

module.exports = {
    components: {
        columnsDropdown
    },
    data: function () {
        return {
            api_url: this.$store.state.api_url,
            tooltips:[false,false,false],
            isOrderBeingDelivered: {},
            clickStartTime: null,
            clickDuration: 1000, // 1 second,
            columnsPageName: "onthewayColumns",
            clickTimeout: null,
            columns: [
                { name: "TUD", visible: true, showActualTime: false },
                { name: "TGT", visible: true, showActualTime: false },
                { name: "EDT", visible: true, showActualTime: false },
            ]
        };
    },
    created: function () { 
        // Load columns from localStorage, or use defaults if none exist
        const storedColumns = localStorage.getItem(this.columnsPageName);
        this.columns = storedColumns ? JSON.parse(storedColumns) : this.columns
    },
    watch: {
        columns: {
            handler(newColumns) {
                localStorage.setItem(this.columnsPageName, JSON.stringify(newColumns));
            },
            deep: true 
        }
    },
    computed: {
        retailOptimization: function () {
            return this.$store.getters["orders/getStore"]['retailOptimization']
        },
        getDrivers: function () {
            return this.$store.getters["drivers/getDrivers"];
        },
        selectedOrders: function () {
            return this.$store.getters["orders/selectedOrders"];
        },
        onTheWayOrders: function () {
            return this.$store.getters["orders/onTheWayOrders"];
        },
    },
    methods: {
        handleColumnsUpdate(updatedColumns) {
            this.columns = updatedColumns;
        },
        addMinutesToCurrentTime: (a) => timeFunctions.addMinutesToCurrentTime(a),
        toggleShowActualTime() {
            if (this.columns.length === 0) return;
            const newValue = !this.columns[0].showActualTime;
            this.columns.forEach(col => {
                col.showActualTime = newValue;
            });
        },
        startClick() {
            this.clickTimeout = setTimeout(() => {
                this.toggleShowActualTime();
            }, this.clickDuration);
        },
        cancelClick() {
            if (this.clickTimeout) {
                clearTimeout(this.clickTimeout);
                this.clickTimeout = null;
            }
        },
        showActualTime(columnName) {
            const column = this.columns.find(({ name }) => name === columnName);
            return column ? column.showActualTime : false;
        },
        isColumnVisible(columnName) {
            const column = this.columns.find(({ name }) => name === columnName);
            return column ? column.visible : false;
        },
        displayTime(columnName, time) {
            if(this.showActualTime(columnName)) return this.addMinutesToCurrentTime(time)
            return time;
        },
        openTrackingPage: function(url) {
            window.open(url, "_blank"); 
        },
        driverShortName(driverId) {
            const matchedDriver = this.getDrivers.find(d => d.driver_id === driverId);
            return matchedDriver ? this.getDriverShortName(matchedDriver.driver_name, matchedDriver.DriverLastName, 7).toUpperCase() : "";
        },
        showTool(a) {
            for(var i = 0; i < this.tooltips.length; i++){
            this.$set(this.tooltips, i, false);
            }
            this.$set(this.tooltips, a, true);
            setTimeout(() => this.$set(this.tooltips, a, false), 5000)
        },
        handleOrderDelivery(order) {
            this.$set(this.isOrderBeingDelivered, order.order_id, true);

            this.$http
                .post(this.api_url + "Api/V1/Orders/DeliverOrder/" + order.order_id)
                .then(
                    (res) => {
                        console.log("ORDER DELIVERED SUCCESSFULLY");
                    },
                    (res) => {
                        console.log("Failed to deliver order", res);
                    }
                )
                .finally(() => {
                    this.$set(this.isOrderBeingDelivered, order.order_id, false);
                });
        },
        elapsedTime: (a) => timeFunctions.elapsedTime(a),
        stringTimeDiff: (a, b) => timeFunctions.stringTimeDiff(a, b),
        shortAddress: (address) => orderFunctions.getShortAddress(address),
        getShortName: (name) => orderFunctions.getShortName(name),
        getDriverShortName: (fname, lname, limitFirstName) => orderFunctions.getDriverShortName(fname, lname, limitFirstName),
        getOrderSourceLogo: (source) => orderFunctions.getOrderSourceLogo(source),
        getShortOrderNumber: (orderNumber) => orderFunctions.getShortOrderNumber(orderNumber),
        formatTime: (a) => timeFunctions.formatTime(a),
    },
    filters: {},
};
</script>

<style scoped>
.order-details-area{
    max-height: calc(100vh - 140px);
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}
</style>
