<template>
<div class="order-detail-page">
    <!-- Returned Orders -->
    <div class="columns table-headings is-size-7">
        <div class="column is-1 name-column has-text-left move-text-up">NAME</div>
        <div class="column is-1 order-number-column has-text-left move-text-up">SOURCE</div>
        <div class="column is-1 order-number-column has-text-left move-text-up">#</div>
        <div class="column address-column has-text-left move-text-up">ADDRESS</div>
        <div class="column time-column has-text-centered" v-if="!retailOptimization && isColumnVisible('TGT')">
          <div class="tooltip" v-show="tooltips[0]">
            Target time
            <div class="tooltip-point"></div>
          </div>
          <a class="button is-small has-text-weight-medium" v-on:click="showTool(0)" @mousedown="startClick" 
          @mouseup="cancelClick" @mouseleave="cancelClick">TGT</a>
        </div>
        <div class="column time-column has-text-centered" v-if="isColumnVisible('EDT')">
          <div class="tooltip" v-show="tooltips[1]">
            Estimated delivery time
            <div class="tooltip-point"></div>
          </div>
          <a class="button is-small has-text-weight-medium" v-on:click="showTool(1)" @mousedown="startClick" 
          @mouseup="cancelClick" @mouseleave="cancelClick">EDT</a>
        </div>
        <div class="column new-time-column new-time-column has-text-left move-text-up">ENTRY</div>
        <div class="column new-time-column new-time-column has-text-left move-text-up">ATTEMPED</div>
        <div class="column is-1 driver-column has-text-left move-text-up">DRIVER</div>
        <div class="column is-1 driver-column has-text-left move-text-up">REF ID</div>
        <div class="column is-1 display-column-dropdown">
            <columnsDropdown
                :columns="columns"
                @update-columns="handleColumnsUpdate"
            />
        </div>
    </div>

    <div class="order-details-area" v-if="returnedOrders.length">
        <div class="columns table-rows is-size-7" v-for="o in returnedOrders" v-bind:key="o.order_id" v-on:click="$emit('toggleOrderSelection', o)">

            <div class="column is-1 name-column has-text-left text-bold"
                :class="
                selectedOrders.filter((s) => s == o.order_id).length === 1
                    ? 'has-background-link'
                    : ''
                ">
                    {{ getShortName(o.customer_name).toUpperCase() }}
            </div>
            <div class="column is-1 order-number-column has-text-left">
                <img class="img source-logo" :src="getOrderSourceLogo(o.OrderSource)" />
            </div>
            <div class="column is-1 order-number-column has-text-left">
                {{ o.source_id  ? getShortOrderNumber(o.source_id ) : o.order_number }}
            </div>
            <div class="column address-column has-text-left">{{ (o.address).toUpperCase() }}</div>
            <div class="column time-column has-text-centered" v-if="!retailOptimization && isColumnVisible('TGT')">
                {{ o.target_delivery_time_minutes }}
            </div>
            <div class="column time-column has-text-weight-bold has-text-centered" 
                :class="
                    o.target_delivery_time_minutes + 10 < o.expected_time_minutes != null && o.external_delivery_id == null ? o.expected_time_minutes :
                         -stringTimeDiff(o.entry_time, o.ExternalEstimatedTimeToDeliver || o.estimated_delivery_time)
                    ? 
                    ' will-be-late has-text-weight-bold '
                    : 
                    o.target_delivery_time_minutes < o.expected_time_minutes != null && o.external_delivery_id == null ? o.expected_time_minutes :
                         -stringTimeDiff(o.entry_time, o.ExternalEstimatedTimeToDeliver || o.estimated_delivery_time)
                    ? 
                    'orange-color has-text-weight-bold' 
                    : 
                    'green-color has-text-weight-bold'
                "
                v-if="isColumnVisible('EDT')"
            >
                {{ retailOptimization || showActualTime("EDT")
                    ? (o.estimated_delivery_time || o.ExternalEstimatedTimeToDeliver 
                        ? formatTime(o.estimated_delivery_time || o.ExternalEstimatedTimeToDeliver) 
                        : '--') 
                    : (o.expected_time_minutes != null && (o.external_delivery_id == null || o.external_delivery_id == '') ? o.expected_time_minutes :
                        o.ExternalEstimatedTimeToDeliver ?
                         -stringTimeDiff(o.entry_time, o.ExternalEstimatedTimeToDeliver) : "--" ) 
                }}
            </div>
            <div class="column new-time-column has-text-centered">
                {{ new Date(parseInt(o.entry_time.substr(6))).toLocaleTimeString([], {hour: '2-digit', minute: '2-digit', hour12: true}) }}
            </div>
            <div class="column new-time-column has-text-centered">
                {{ (o.delivery_time || o.ExternalEstimatedTimeToDeliver) ? new Date(parseInt((o.delivery_time || o.ExternalEstimatedTimeToDeliver).substr(6))).toLocaleTimeString([], {hour: '2-digit', minute: '2-digit', hour12: true}) : '--' }}
            </div>
            <div class="column is-1 driver-column has-text-left">
                {{
                    o.driver_id ? driverShortName(o.driver_id) : 
                    o.external_delivery_source ? o.external_delivery_source.toUpperCase() :
                    '--'
                }}
            </div>
            <div class="column is-1 driver-column has-text-left">
                    {{ o.external_delivery_id ? getShortOrderNumber(o.external_delivery_id ) : '--' }}
            </div>
            <div class="column is-1 button-column has-text-centered">
                <a class="button is-outlined is-primary is-small" v-on:click="$emit('remakeOrder', o, false)" @click.stop="true">
                    <span class="fa fa-refresh" title="Remake"></span>
                </a>
            </div>
        </div>
    </div>
</div>
</template>

<script>
var timeFunctions = require("../../js/timeFunctions.js");
var orderFunctions = require("../../js/orderFunctions.js");
var columnsDropdown = require("../common/columns_dropdown.vue").default;

module.exports = {
    components: {columnsDropdown},
    data: function () {
        return {
            tooltips: [false,false,false],
            clickStartTime: null,
            clickDuration: 1000, // 1 second,
            clickTimeout: null,
            columnsPageName: "returnsColumns",
            columns: [
                { name: "TGT", visible: true, showActualTime: false },
                { name: "EDT", visible: true, showActualTime: false },
            ]
        };
    },
    created: function () { 
        // Load columns from localStorage, or use defaults if none exist
        const storedColumns = localStorage.getItem(this.columnsPageName);
        this.columns = storedColumns ? JSON.parse(storedColumns) : this.columns
    },
    watch: {
        columns: {
            handler(newColumns) {
                localStorage.setItem(this.columnsPageName, JSON.stringify(newColumns));
            },
            deep: true 
        }
    },
    computed: {
        retailOptimization: function () {
            return this.$store.getters["orders/getStore"]['retailOptimization']
        },
        getDrivers: function () {
            return this.$store.getters["drivers/getDrivers"];
        },
        selectedOrders: function () {
            return this.$store.getters["orders/selectedOrders"];
        },
        returnedOrders: function () {
            return this.$store.getters["orders/returnedOrders"];
        },
    },
    methods: {
        handleColumnsUpdate(updatedColumns) {
            this.columns = updatedColumns;
        },
        addMinutesToCurrentTime: (a) => timeFunctions.addMinutesToCurrentTime(a),
        toggleShowActualTime() {
            if (this.columns.length === 0) return;
            const newValue = !this.columns[0].showActualTime;
            this.columns.forEach(col => {
                col.showActualTime = newValue;
            });
        },
        startClick() {
            this.clickTimeout = setTimeout(() => {
                this.toggleShowActualTime();
            }, this.clickDuration);
        },
        cancelClick() {
            if (this.clickTimeout) {
                clearTimeout(this.clickTimeout);
                this.clickTimeout = null;
            }
        },
        showActualTime(columnName) {
            const column = this.columns.find(({ name }) => name === columnName);
            return column ? column.showActualTime : false;
        },
        isColumnVisible(columnName) {
            const column = this.columns.find(({ name }) => name === columnName);
            return column ? column.visible : false;
        },
        displayTime(columnName, time) {
            if(this.showActualTime(columnName)) return this.addMinutesToCurrentTime(time)
            return time;
        },
        showTool(a) {
            for(var i = 0; i < this.tooltips.length; i++){
                this.$set(this.tooltips, i, false);
            }
            this.$set(this.tooltips, a, true);
            setTimeout(() => this.$set(this.tooltips, a, false), 5000)
        },
        driverShortName(driverId) {
            const matchedDriver = this.getDrivers.find(d => d.driver_id === driverId);
            return matchedDriver ? this.getDriverShortName(matchedDriver.driver_name, matchedDriver.DriverLastName, 7).toUpperCase() : "";
        },
        elapsedTime: (a) => timeFunctions.elapsedTime(a),
        stringTimeDiff: (a, b) => timeFunctions.stringTimeDiff(a, b),
        shortAddress: (address) => orderFunctions.getShortAddress(address),
        getShortName: (name) => orderFunctions.getShortName(name),
        getOrderSourceLogo: (source) => orderFunctions.getOrderSourceLogo(source),
        getShortOrderNumber: (orderNumber) => orderFunctions.getShortOrderNumber(orderNumber),
        getDriverShortName: (fname, lname, limitFirstName) => orderFunctions.getDriverShortName(fname, lname, limitFirstName),
        formatTime: (a) => timeFunctions.formatTime(a),
    },
    filters: {},
};
</script>

<style scoped>
.order-details-area{
    max-height: calc(100vh - 140px);
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}
</style>
